@import "../bootstrap/bootstrap";
@import (inline) "angular-tree-control/css/tree-control.css";
@import (inline) "angular-tree-control/css/tree-control-attribute.css";
@import "login.less";
@import "news.less";
@import "catalog.less";

.main-wrapper{
  margin-top:50px;
  padding:20px;
  background: #F9FAFC;
}

.table{
  td, th{
    background:#fff;
  }
}

.middle-textarea{
  height:125px;
}

.module-menu-item{
  cursor:pointer;
}

input[type="text"], textarea, select{
  .form-control();
}

.group-header{
  display:block;
  font-size:16px;
  margin-top:15px;
}

.option-label{
  font-weight:normal;
  margin-right:10px;
  margin-left:20px;
}