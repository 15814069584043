.news-wrapper{
  width:90%;
  max-width:1000px;
}

.news-image-wrapper{
  display:inline-block;
  border:2px solid #ddd;
  padding:10px;
  .news-image{
    height:100px;
  }
}

