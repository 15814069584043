.catalog-main{
  display:table;
  width:100%;

  .catalog-tree-wrapper, .work-area-wrapper{
    display:table-cell;
    vertical-align:top;
  }

  .catalog-tree-wrapper{
    padding:10px;
    min-width:400px;
    width:500px;
    max-width:700px;
  }

  .highlighted .tree-leaf .tree-label{
    background:yellow;
  }
}

.product-added{
  color:#ccc;
}
